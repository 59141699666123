* {
    box-sizing: border-box;
    padding: 0;
}

body {
    font-family: "Lucida Grande";
    background: #FBFFF1;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

h1 {
    color: #404E5C;
    font-family: 'Permanent Marker';
    font-size: x-large;
}

.cooker {
    width: 350px;
    height: 350px;
    margin-bottom: 20px;
}


.image img {
    width: 50px;
    height: 50px;
}

h2 {
    text-decoration: none;
    color: #F7B801;
    text-align: center;
    font-family: 'Permanent Marker';
    font-size: xx-large;
    margin: 10px;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: #F7B801;
}

input {
    width: 300px;
    text-align: center;
    padding: 10px;
    font-size: 1rem;
    margin-bottom: 30px;
    border-radius: 10px;
    border: 1px solid #4c6d7d;
}

.btn-nav {
    border: 1px solid black;
    width: 50vw;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.meals-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.meal-card {
    flex: 1 0 20%;
    min-width: 180px;
    max-width: 380px;
    border: 3px solid #F7B801;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    background: #404E5C;
    text-align: center;
}

.yticon {
    width: 20px;
    height: 20px;
    margin-right: 9px;
}

.link-youtube {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
}

img {
    width: 90%;
    border-radius: 10px;
}

p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
    color: #F6E8EA;
    text-align: center;
}

.instructions {
    text-align: justify;
    padding: 0 15px;
    font-size: 0.9em;
}

.footer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #404E5C;
    text-align: center;
    height: 50px;
    width: 100%;
    flex-shrink: 0;
    color: floralwhite;
}